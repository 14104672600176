.__pagination__ {
  width: 90%;
  margin: auto;
}

.__page-item__ {
  flex-grow: 1;
  text-align: center;
}

.details {
  padding: 0.8rem 0;
}

.details ul {
  padding-left: 10px;
  list-style-type: square;
  max-width: 800px;
  word-break: break-all;
}

.details li {
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
  color: rgb(56, 56, 56);
}

.details h6 {
  color: #4d7be9;
}

.details li::marker {
  font-size: 1.5rem;
  color: #4d7be9;
}

.name {
  font-size: 1.2rem;
  font-weight: 500;
}

.__page_fix__ {
  position: sticky;
  bottom: 0;
  background: white;
  z-index: 5;
}
